.mobile{
	display: none;
}
.hidden{
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}
.m20{
    margin:20px;
}
.mt10{
    margin-top:10px;
}
.mt100{
    margin-top:100px;
}
.mb10{
    margin-bottom:10px;
}
.mr10{
    margin-right: 10px;
}
.ml10{
    margin-left: 10px;
}
.ml130{
    margin-left: 130px;
}
.m20{
    margin:20px !important;
}
.mb20{
	margin-bottom:20px !important;
}
.ml100{
    margin-left:100px;
}
.mr100{
    margin-right: 100px;
}
.pb50{
    padding-bottom:50px !important;
}
.pt10{
	padding-top:1px !important;
}
label{
    cursor: pointer;
}
.ib{
    display: inline-block;
}
.oh{
    overflow: hidden;
}
.cb{
    clear: both;
}
.fl{
    float: left;
}
.fr{
    float: right;
}
html,body{
    background-color: #fff;
}
.sans-serif{
    font-family: 'Roboto', sans-serif !important;
}
.serif{
    font-family: "Noto Sans" ,serif !important;
}
.bold{
    font-weight: bold;
}
p#totop{
    width:50px;
    height: 50px;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    background:url("../images/icon_totop.svg") center top no-repeat;
    position: fixed;
    right:20px;
    top:50vh;
}
p#totop:hover{
    background-position: center bottom;
}
p#totop a{
    display: block;
    width:100%;
    height: 100%;
}

#headerWrapper {
    width: 100%;
    min-width: 1280px;
    border-bottom: 1px solid #999;
}
div#header{
    width:100%;
    position: relative;
}
div#header h1{
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    background:url("../images/logo.svg") no-repeat;
    width:81px;
    height: 38px;
    background-size:81px;
    margin:20px auto;
}
div#header h1 a{
    display: block;
    width:100%;
    height: 100%;
}
ul#nav{
    width:1080px;
    height: 40px;
    margin:50px auto 0;
    display: flex;
    justify-content: space-between
}
ul#nav li{
    text-align: center;
    width:160px;
    height: 40px;
    line-height: 40px;
}
ul#nav li a,
ul#nav2 li a{
    width:100%;
    height: 100%;
    display: block;
}
ul#nav li:hover a,
ul#nav li.active a{
    background:#44B035;
    color:#fff;
    border-radius: 4px;
}
div.nav2Wrapper{
    width:100%;
    min-width: 960px;
    background:#eee;
    height: 60px;
    margin-top:10px;
}
ul#nav2{
    width:960px;
    margin:auto;
    display: flex;
    justify-content: flex-start;
}
ul#nav2 li{
    width:180px;
    height: 40px;
    margin:10px 10px 10px 0;
    text-align: center;
    line-height: 40px;
}
ul#nav2 li:hover a,
ul#nav2 li.active a{
    border:2px solid #44B035;
    color:#44B035;
    border-radius: 4px;
    background:#fff;
}
p.time {
    font-weight: bold;
    color:#999;
    font-size:20px;
    text-align: center;
    margin:28px auto 14px;
}
p.time span{
    padding-left:10px;
}

div.contents {
    width:100%;
    max-width:1220px;
    margin:auto;
    padding:0 30px;
    overflow: hidden;
}

div.contentsWrapper {
    width:100%;
    position: relative;
}


div#footerWrapper {
    width:100%;
    height: 80px;
    background:#ebebeb;
    border-top:1px solid #d5d5d5;
    padding:20px 0;
    margin-top:30px;
    overflow: hidden;
}
div#footer{
    width:100%;
    max-width: 1280px;
    height: 100%;
    margin:auto;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items:center;
}


img.login_logo {
    margin:100px auto 30px;
    width:162px;
    height: 76px;
    display: block;
}

div.login {
    width:360px;
    margin: auto;
}
div.login input[type=text],
div.login input[type=email],
div.login input[type=password]{
    border:1px solid #44B035;
    line-height: 2em;
    width:calc(100% - 10px);
    padding:0 5px;
    border-radius: 4px;
    margin-bottom:20px;
}
div.login input[type=text]:focus,
div.login input[type=email]:focus,
div.login input[type=password]:focus{
    background:#fbe7a4;
}
div.login input[type=submit]{
    width:100%;
    height: 3em;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
}
div.login input.login{
    color: #fff;
    background:#44B035;
    margin-bottom: 20px;
}
div.login input.login:hover{
    background:#E71F19;
}
div.login input.entry{
    color: #44B035;
    border: 1px solid #44B035;
    margin-bottom: 10px;
}
div.login input.entry:hover{
    color: #fff;
    background: #E71F19;
    margin-bottom: 10px;
}
div.login label{
    display: block;
}
p.error{
    padding:10px;
    border-radius: 4px;
    background:#F5C819;
    color:#ff432f;
    margin-bottom: 20px;
}
div.login p.forget{
    color:#44B035;
    text-align: center;
}



div.top {
    margin:50px auto;
    width:440px;
    text-align: center;
    color:#fff;
}
div.top p.test_name{
    font-size:24px;
    font-weight: bold;
    margin:30px auto 10px;
    color:#fff;
}
div.top p.test_timer{
    font-weight: bold;
    margin-top:30px;
    margin-bottom: 10px;
}
div.top p.start{
    width:240px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    background:#fff;
    margin:30px auto;
    border-radius: 4px;
    font-size: 20px;
}
div.top p.start a{
    color:#44B035;
    display: block;
    width:100%;
    height: 100%;
}
div.top p.start:hover{
    background:#1AC875;
}
div.top p.start a:hover{
    color:#fff;
}


.modal_wrap input {
  display: none;
}

.modal_overlay {
  display: flex;
  justify-content: center;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  transition: opacity 0.5s, transform 0s 0.5s;
  transform: scale(0);
}

.modal_trigger {
  position: absolute;
  width: 100%;
  height: 100%;
}

.modal_content {
    align-self: center;
    /*box-sizing: border-box;*/
    line-height: 1.4em;
    background: #fff;
    transform: scale(0.3);
    transition: 0.5s;
    position: relative;

}

.close_button {
  position: absolute;
  top: 20px;
  right: 16px;
  cursor: pointer;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  background:url("../images/close_icon.svg") center center no-repeat;
  background-size:30px;
}
.close_button:hover{
    background-image:url("../images/close_icon_d.svg")
}
.modal_wrap{

}
.modal_content{
    display: block;
    height: 90vh;
    width:80vw;
    max-width:1280px;
}
.modal_wrap input:checked ~ .modal_overlay {
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.5s;
}

.modal_wrap input:checked ~ .modal_overlay .modal_content {
  transform: scale(1);
    border-radius: 10px;
}
.modal_content h2{
    line-height: 66px;
    text-align: center !important;
    border-bottom:1px solid #ccc !important;
    font-size: 28px;
    font-weight: bold;
}
.open_button {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 30px;
  margin: auto;
  padding: 8px 16px;
  color: #444;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6);
  border-radius: 16px;
  cursor: pointer;
  background:#fff;
}
.open_button_toterm {
    text-align: center;
    margin:50px auto 10px;
    cursor: pointer;
    display: block;
}
.open_button_toinstraction {
    width:100%;
    height: 100%;
    display: block;
    cursor: pointer;
}
/*スクロールバー全体*/
::-webkit-scrollbar {
    width: 10px;
}
/*スクロールバーの軌道*/
::-webkit-scrollbar-track {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .1);
}
/*スクロールバーの動く部分*/
::-webkit-scrollbar-thumb {
  background-color: rgba(50, 50, 50, .5);
  border-radius: 10px;
  box-shadow:0 0 0 1px rgba(255, 255, 255, .3);
}
div.operationWrapper{
    width:1080px;
    margin:auto;
    display: flex;
    justify-content: space-between;
    padding:20px;
    align-items: center;
}
ul.operation {
    width:720px;
    margin:20px auto;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    border:3px solid #33b035;
    padding:20px;
    border-radius: 4px;
}
ul.operation2 {
    width:300px;
    margin:20px auto;
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
}
ul.operation li,
ul.operation2 li{
    width:150px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    border-radius: 4px;
}
ul.operation2 li{
    width:150px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    border-radius: 4px;
    margin-left:20px;
}
ul.operation li a,
ul.operation2 li a{
    display: block;
    width:100%;
    height: 100%;
    color:#fff;
}
ul.operation li.save,
ul.operation2 li.save{
    background:#44B035;
}
ul.operation li.discard,
ul.operation2 li.discard{
    background:#ccc;
}
ul.operation li.save:hover,
ul.operation2 li.save:hover{
    background:#ff432f;
}
ul.operation li.discard:hover,
ul.operation2 li.discard:hover{
    background:#44B035;
}
ul.operation li.preview,
ul.operation2 li.preview{
    background:#44B035;
}
ul.operation li.preview:hover,
ul.operation2 li.preview:hover{
    background:#ff432f;
}
div.contentsInfoWrapper{
    width:1080px;
    display: flex;
    justify-content: space-between;
    margin:auto;
}
div.topImage{
    width:calc(40% - 5px);
    text-align: center;
}
div.topImage h2{
    text-align: center;
    margin-bottom:10px;
}
div.title h2::after,
div.topImage h2::after{
    content: "※";
    color:red;
    padding-left:3px;
}
dl.contentsInfo {
    width:calc(60% - 5px);
    margin:20px auto;
    overflow: hidden;
    border-bottom:1px solid #ccc;
    padding-left:3px;
}
.qr dl.contentsInfo {
    width:calc(80%);
    margin:20px auto;
    overflow: hidden;
    border-bottom:1px solid #ccc;
    padding-left:3px;
}
dl.contentsInfo dt{
    width:120px;
    float: left;
    clear: both;
    margin-bottom:30px;
    border-top:1px solid #ccc;
    padding-top:10px;
}
.qr dl.contentsInfo dt{
    width:160px;
}
.must::after{
    content: "※";
    color:red;
}
dl.contentsInfo dd{
    width:calc(100% - 120px);
    float: right;
    border-top:1px solid #ccc;
    padding-top:10px;
}
.qr dl.contentsInfo dd{
    width:calc(100% - 160px);
}
input[type=text],
input[type=password]{
    padding:0 5px;
    background:#efefef;
    border: 1px solid #ccc;
    border-radius: 4px;
}
input[type=text].full{
    width:calc(100% - 12px);
}
input[type=text].half,
input[type=password].half{
    width:calc(50% - 12px);
    margin-bottom:10px;
}
input[type=text].quarter,
input[type=password].quarter{
    width:calc(35% - 12px);
    margin-bottom:10px;
}
input[type=text].w40{
    width:calc(40% - 12px);
}
input[type=text].w45{
    width:calc(45% - 12px);
}
input[type=text].w100px{
    width:calc(100px);
}
input[type=text].calendar{
    cursor: pointer;
}

input[type=button]{
    padding:5px 20px;
    text-align: center;
    background:#44B035;
    color:#fff;
    cursor: pointer;
    border-radius: 4px;
}
input[type=button]:hover{
    background:#ff432f;
}
input[type=checkbox]{
    width:20px;
    height: 20px;
    border:3px solid #666;
    vertical-align: top;
    margin-right: 5px;
}
input[type=radio]{
    width:20px;
    height: 20px;
    border:3px solid #666;
    border-radius: 10px;
    vertical-align: top;
    margin-right: 5px;
}
input[type=checkbox]:checked,
input[type=radio]:checked{
    background:#44B035;
    border-color:#44B035;
}
input[type=checkbox] + label{
    margin-right: 20px;
}
label{
    margin-right: 10px;
}
textarea{
    border:1px solid #ccc;
    background:#efefef;
    height: 150px;
    padding:10px;
    width:calc(100% - 22px);
    border-radius: 4px;
    display: block;
    margin-bottom: 10px;
}

ul.lang{
    margin-top:10px;
    margin-bottom:10px;
}
ul.lang li{
    width:calc(100% - 12px);
    border:1px solid #ccc;
    border-radius: 4px;
    height: 25px;
    line-height: 25px;
    padding:5px;
    margin-bottom: 5px;
    position: relative;
    text-indent: 10px;
}
ul.lang span{
    width:24px;
    height: 24px;
    background-repeat:no-repeat;
    background-position: center bottom;
    background-size:24px;
    position: absolute;
    display: block;
    cursor: pointer;
    top:5px;
}
ul.lang span:hover{
    background-position: center top;
}
ul.lang span.delete,
div.collectionList span.delete{
    background-image:url("../images/lang_delete.svg");
    right:45px;
    cursor: pointer;
}
ul.lang span.move,
div.collectionList span.move{
    background-image:url("../images/lang_move.svg");
    right:10px;
    cursor:move
}
div.collectionList span{
    position: absolute;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 24px;
    display: block;
    cursor: pointer;
    top: 10px;
}
div.collectionList span.delete{
    right:50px;
}
div.collectionList span.move{
    right:20px;
}
div.contentsBox {
    padding:20px;
    width:920px;
    border:2px solid #999;
    border-radius: 4px;
    margin:20px auto;
    position: relative;
}
div.contentsHeader{
    display: flex;
    justify-content: flex-start;
}
div.contentsBox h2{
    font-size:18px;
    font-weight: bold;
    margin-bottom: 10px;
}
div.number{
    width:130px;
    margin-right: 40px;
    text-align: center;
    font-weight: bold;
    font-size:18px;
    pointer-events: stroke;
}
.must::after{
    content: "※";
    color:red;
    padding-left:3px;
}
input[type=text].number{
    font-size:48px;
    height: 72px;
    text-align: center;
}
div.contentsBox p.close,
div.collectionList p.close{
    position: absolute;
    top:10px; 
    right: 20px;
    border:2px solid #44B035;
    width:80px;
    line-height: 24px;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
}
div.contentsBox p.delete,
div.collectionList p.delete{
    position: absolute;
    top:10px; 
    right: 110px;
    border:2px solid #44B035;
    width:80px;
    line-height: 24px;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
}
div.qr p.delete{
    right:20px;
}
div.contentsBox p.close:hover,
div.contentsBox p.delete:hover,
div.collectionList p.close:hover,
div.collectionList p.delete:hover{
    background:#44B035;
    color:#fff;
}
div.contentsBox div.title{
    width:calc(100% - 150px);
}
dl.title{
    overflow: hidden;
}
dl.title dt,
dl.title dd{
    
}
dl.title dt{
    width:100px;
    float: left;
    clear: both;
    margin-bottom:20px;
}
dl.title dd{
    width:calc(100% - 110px);
    float: right;
}
div.indent,
dl.indent{
    margin-left:100px;
    margin-bottom: 10px;
}
dl.indent dt{
    width:80px;
    float: left;
    clear: both;
}
dl.indent dd{
    width:calc(100% - 100px);
    float: right;
}
div.indent h3{
    margin:5px 0;
}
img#preview,
img#preview1,
img#preview2{
    display: block;
    margin:10px 0;
    width:240px;
    height: 160px;
    object-fit: contain;
    outline:dashed;
    outline-color: #ccc;
    outline-width: 4px;
}
img#preview1{
    height: 80px;
}

img#topImage{
    display: block;
    margin:10px auto;
    width:240px;
    height: 480px;
    object-fit: contain;
    outline:dashed;
    outline-color: #ccc;
    outline-width: 4px;
}

p.addContents {
    width:200px;
    height: 40px;
    line-height: 40px;
    background:#44B035;
    text-align: center;
    color:#fff;
    margin:20px auto;
    cursor: pointer;
    border-radius: 4px;
}
p.addContents a{
    color:#fff;
}
p.addContents:hover,
p.addContents:hover a{
    background:#ff432f;
    color:#fff;
}

table.contentsList {
    width: 100%;
    margin:20px auto;
}
table.contentsList thead{
    border-bottom: 1px solid #ccc;
}
table.contentsList th{
    text-align: center;
    padding:3px 0;
}
table.contentsList td{
    padding:10px;
    border-bottom: 1px solid #ccc;
}
table.contentsList tr td:nth-child(7){
    text-align: center;
}
table.contentsList span.publish{
    color:#44B035;
    font-weight: bold;
}
table.contentsList a{
    border:2px solid #44B035;
    padding:2px 5px;
    border-radius: 4px;
    color:#44B035;
    font-size:14px;
}
table.contentsList a:hover{
    background:#44B035;
    color:#fff;
}


div.listPaginate {
    margin-top:20px;
    width:100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
div.listPaginate a{
    width:24px;
    height:24px;
    display:block;
    background-position:center bottom;
    background-repeat:no-repeat;
    background-size:24px;
    margin:0 10px;
}
div.listPaginate a:hover{
    background-position:center top;
}
div.listPaginate a.prev{
    background-image:url("../images/page_prev.svg");
}
div.listPaginate a.next{
    background-image:url("../images/page_next.svg");
}
div.constraints_passwd,
div.constraints_gps,
div.constraints_gps2{
    margin:5px 0 5px 30px;
}
div.constraints_gps input,
div.constraints_gps2 input{
    margin-bottom:5px;
}
div.constraints_passwd{
    margin-left:calc(37px + 2em);
}
div.collection {
    width:calc(620px - 2px);
    border:1px solid #333;
    padding:20px;
    margin:20px auto 40px;
    position: relative;
    border-radius: 4px;
}
div.collection h3{
    position: absolute;
    top:-.75em;
    left:30px;
}
div.collection h3 span{
    padding:0 10px;
    background:#fff;
}

table.collection_item {
    width:100%;
}
table.collection_item thead{
    border-bottom:2px solid #ccc;
    text-align: center;
}
table.collection_item td{
    padding:10px;
}
table.collection_item tr td:nth-child(1),
table.collection_item tr td:nth-child(2){
    width:40%;
}
table.collection_item select,
div.collectionList select,
div.qr select{
    border:1px solid #ccc;
    border-radius: 4px;
    height: 36px;
    line-height: 36px;
    padding:0 20px 0 10px;
    background:url("../images/arrow_down.svg") right 5px center no-repeat;
    background-size: 10px;
}

p.addCollection {
    width:80px;
    height: 30px;
    text-align: center;
    background:#44B035;
    color:#fff;
    line-height: 30px;
    border-radius: 4px;
    cursor: pointer;
}

div.collectionList {
    position: relative;
    padding:50px 20px 20px;
    width:calc(920px - 2px);
    margin:20px auto;
    border:1px solid #333;
    overflow: hidden;
    border-radius: 4px;
}
dl.collectionList {
    width:100%;
}
dl.collectionList dt{
    width:20%;
    float: left;
    clear: both;

}
dl.collectionList dd{
    width:80%;
    float: right;
    margin-bottom:10px;
}
textarea.addItem{
    padding:10px;
    border:1px solid #ccc;
    border-radius: 4px;
    margin-top:10px;
    width:calc(100% - 12px);
    height: 120px;
}

div.error {
  position: absolute;
  margin: 1.5em 0;
  padding: 7px 10px;
  max-width: 200px;
  max-height: 120px;
  color: #fff;
  background: rgba(255,0,0,0.8);
  text-align: justify;
  top:-85px;
  left:20px;
}
div.error:before{
  content: "";
  position: absolute;
  top: 100%;
  left: 20%;
  margin-left: -15px;
  border: 15px solid transparent;
  border-top: 15px solid rgba(255,0,0,0.8);
}

dl.voiceguideTitle {
    width:956px;
    margin:20px auto;
    border:2px solid #44B035;
    border-radius: 4px;
    overflow: hidden;
}
dl.voiceguideTitle dt{
    width:20%;
    float: left;
    padding:10px 0;
    text-align: center;
}
dl.voiceguideTitle dd.title{
    width:65%;
    float: left;
    padding:10px 0;
    text-indent: 20px;
    font-size:18px;
    font-weight: bold;
}
dl.voiceguideTitle dd.status{
    width:15%;
    float: left;
    padding:10px 0;
    text-indent: 20px;
    text-align: center;
}
dd.status span{
    padding:3px 6px;
    color:#fff;
    border-radius: 4px;
}
dd.status span.nopublic{
    background-color:#ccc;
}
dd.status span.public{
    background-color:#44B035;
}


div.movie_cotent {
    
}

div.rental {
    margin:10px;
    margin-top:30px;
    background:#ccc;
    display: inline-block;
    padding:20px 40px;
}
div.rental h3{
    text-align: left;
    border-bottom:1px solid #333;
    margin-bottom:10px;
}
div.rental p.attention{
    color: red;
    margin-bottom:10px;
}

span#sea {
    cursor: pointer;
    padding-left:10px;
}

div.previewWrapper{
    width:1080px;
    margin:50px auto;
    overflow: hidden;
}
div.preview {
    padding:48px 17px 48px;
    width:292px;
    height: 565px;
    height: 600px;
    background:url("../images/smartphoneBg.png") no-repeat;
    background-size:100%;
    position: relative;
    float: left;
}
div.preview img.top{
    width:292px;
    height: 565px;
    object-fit: cover;
    position: absolute;
    top:48px;
    left:17px;
    z-index: 0;
}

ul.language {
    position: absolute;
    left:0;
    right:0;
    top:calc(48px + (565px / 2));
    margin:auto;
    z-index: 2;
}
ul.language li{
    width:calc(100% - 60px);
    height:45px;
    line-height: 45px;
    text-align:center;
    background:#fff;
    margin:10px 30px;
}

div.preContents {
    padding:20px 20px;
}
div.preImg{
    width:700px;
    float: right;
    position: relative;
}
p.preNum{
    width:128px;
    height: 64px;
    float: left;
    font-size: 64px;
    font-weight: bold;
    align-items: center;
    text-align: center;
    line-height: 1em;
    color:#44B035
}
p.preTitle{
    width:calc(700px - 108px - 20px);
    font-size:24px;
    margin-bottom:20px;
    color:#44B035;
    line-height: 2em;
    text-align: left;
    float: right;
    margin-top:5px;
}
div.preImageWrapper{
    width:350px;
    height: 250px;
    background:url("https://dummyimage.com/350x250/333333/ffffff&text=画像無し") no-repeat;
    marin:20px;
    float: left;
    clear: both;
    margin-bottom:50px;
    text-align: center;
}
div.preImageWrapper img{
    width:100%;
    height: 100%;
    object-fit: contain;
    object-position: 50% 50%;
    margin:auto;
}
div.preContents audio{
    width:300px;
    margin:20px auto;
    float: right;
}
dl.preview{
    width:100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom:2px solid #44B035;
    margin-bottom:30px;
    padding:0 40px;
}
dl.preview dt{
    font-size:60px;
    width:120px;
    text-align: right;
    margin-right: 10px;
}
dl.preview dd{
    font-size:24px;
    width:calc(100% - 130px);
}
div.preTextWrapper {
    height: 400px;
    overflow-y: auto;
    clear: both;
    padding-top:30px;
    box-sizing: border-box;
    padding:20px;
    background:#efefef;
    width:100%;
    padding-right: 30px;
}

/* slick */
button.slick-prev {
	background: url("../images/arrow_prev.svg") no-repeat;
	background-size: 30px;
	right: 700px;
}
button.slick-prev:hover {
	background-image: url(../images/arrow_prev_on.svg) !important;
}
button.slick-next {
	background: url("../images/arrow_next.svg") no-repeat;
	background-size: 30px;
	right: 0;
}
button.slick-next:hover {
	background-image: url(../images/arrow_next_on.svg) !important;
}
button.slick-arrow {
	text-indent: -9999px;
	position: absolute;
	width: 30px;
	height: 50px;
	top: 50px;
	z-index: 1;
	border: none;
	cursor: pointer;
}
li.slick-active {
	background-color: #b2f0f4 !important;
}
/* end of slick */


table.report {
    width:calc(100% - 100px);
    margin:20px auto;
}
table.report tr td{
    padding:5px;
}
table.report tr:nth-child(2n) td{
    background:#efefef;
    text-align: center !important;
}
table.report tr:nth-child(2n+1) td{
    text-align: center !important;
}
table.report thead {
    background:#ccc;
}
table.report thead th {
    padding:5px;
    text-align: center;
}
table.report tfoot th{
    padding:5px;
    background:#333;
    color:#fff;
    text-align: center;
}
div.reportDuration {
    margin:20px 0;
}
div.page {
	width: calc(100% - 10px);
	padding: 5px;
	text-align: center;
	margin: 10px auto;
	display: flex;
	overflow: hidden;
	-webkit-justify-content: center; /* Safari */
	justify-content: center;
    font-family: proxima-nova,proxima nova,open sans,Arial,Helvetica,sans-serif;
}
div.page span.current_page {
	background: #44B035;
	padding: 1px;
	min-width: 30px;
	height: 30px;
	line-height: 30px;
	text-align: center;
    color:#fff;
}
div.page a,
a.link_next,
a.link_before{
	display: block;
	background: #CCC;
	padding: 1px;
	min-width: 30px;
	height: 30px;
	line-height: 30px;
	text-align: center;
}
a.link_next {
    padding-left:10px !important;
    padding-right: 10px !important;
    margin-left:4px;
}
a.link_before {
    padding-left:10px !important;
    padding-right: 10px !important;
    margin-right:4px;
}
div.page span:last-child{
}
div.page a:hover {
	background: #44B035;
	color: #fff;
}