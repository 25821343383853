.mobile {
  display: none;
}

.hidden {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.mt10 {
  margin-top: 10px;
}

.mt100 {
  margin-top: 100px;
}

.mb10 {
  margin-bottom: 10px;
}

.mr10 {
  margin-right: 10px;
}

.ml10 {
  margin-left: 10px;
}

.ml130 {
  margin-left: 130px;
}

.m20 {
  margin: 20px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.ml100 {
  margin-left: 100px;
}

.mr100 {
  margin-right: 100px;
}

.pb50 {
  padding-bottom: 50px !important;
}

.pt10 {
  padding-top: 1px !important;
}

label {
  cursor: pointer;
}

.ib {
  display: inline-block;
}

.oh {
  overflow: hidden;
}

.cb {
  clear: both;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

html, body {
  background-color: #fff;
}

.sans-serif {
  font-family: Roboto, sans-serif !important;
}

.serif {
  font-family: Noto Sans, serif !important;
}

.bold {
  font-weight: bold;
}

p#totop {
  width: 50px;
  height: 50px;
  text-indent: 100%;
  white-space: nowrap;
  background: url("icon_totop.94d38551.svg") top no-repeat;
  position: fixed;
  top: 50vh;
  right: 20px;
  overflow: hidden;
}

p#totop:hover {
  background-position: bottom;
}

p#totop a {
  width: 100%;
  height: 100%;
  display: block;
}

#headerWrapper {
  width: 100%;
  min-width: 1280px;
  border-bottom: 1px solid #999;
}

div#header {
  width: 100%;
  position: relative;
}

div#header h1 {
  text-indent: 100%;
  white-space: nowrap;
  width: 81px;
  height: 38px;
  background: url("logo.b9cd493d.svg") 0 0 / 81px no-repeat;
  margin: 20px auto;
  overflow: hidden;
}

div#header h1 a {
  width: 100%;
  height: 100%;
  display: block;
}

ul#nav {
  width: 1080px;
  height: 40px;
  justify-content: space-between;
  margin: 50px auto 0;
  display: flex;
}

ul#nav li {
  text-align: center;
  width: 160px;
  height: 40px;
  line-height: 40px;
}

ul#nav li a, ul#nav2 li a {
  width: 100%;
  height: 100%;
  display: block;
}

ul#nav li:hover a, ul#nav li.active a {
  color: #fff;
  background: #44b035;
  border-radius: 4px;
}

div.nav2Wrapper {
  width: 100%;
  min-width: 960px;
  height: 60px;
  background: #eee;
  margin-top: 10px;
}

ul#nav2 {
  width: 960px;
  justify-content: flex-start;
  margin: auto;
  display: flex;
}

ul#nav2 li {
  width: 180px;
  height: 40px;
  text-align: center;
  margin: 10px 10px 10px 0;
  line-height: 40px;
}

ul#nav2 li:hover a, ul#nav2 li.active a {
  color: #44b035;
  background: #fff;
  border: 2px solid #44b035;
  border-radius: 4px;
}

p.time {
  color: #999;
  text-align: center;
  margin: 28px auto 14px;
  font-size: 20px;
  font-weight: bold;
}

p.time span {
  padding-left: 10px;
}

div.contents {
  width: 100%;
  max-width: 1220px;
  margin: auto;
  padding: 0 30px;
  overflow: hidden;
}

div.contentsWrapper {
  width: 100%;
  position: relative;
}

div#footerWrapper {
  width: 100%;
  height: 80px;
  background: #ebebeb;
  border-top: 1px solid #d5d5d5;
  margin-top: 30px;
  padding: 20px 0;
  overflow: hidden;
}

div#footer {
  width: 100%;
  max-width: 1280px;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  display: flex;
  overflow: hidden;
}

img.login_logo {
  width: 162px;
  height: 76px;
  margin: 100px auto 30px;
  display: block;
}

div.login {
  width: 360px;
  margin: auto;
}

div.login input[type="text"], div.login input[type="email"], div.login input[type="password"] {
  width: calc(100% - 10px);
  border: 1px solid #44b035;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 0 5px;
  line-height: 2em;
}

div.login input[type="text"]:focus, div.login input[type="email"]:focus, div.login input[type="password"]:focus {
  background: #fbe7a4;
}

div.login input[type="submit"] {
  width: 100%;
  height: 3em;
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
}

div.login input.login {
  color: #fff;
  background: #44b035;
  margin-bottom: 20px;
}

div.login input.login:hover {
  background: #e71f19;
}

div.login input.entry {
  color: #44b035;
  border: 1px solid #44b035;
  margin-bottom: 10px;
}

div.login input.entry:hover {
  color: #fff;
  background: #e71f19;
  margin-bottom: 10px;
}

div.login label {
  display: block;
}

p.error {
  color: #ff432f;
  background: #f5c819;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 10px;
}

div.login p.forget {
  color: #44b035;
  text-align: center;
}

div.top {
  width: 440px;
  text-align: center;
  color: #fff;
  margin: 50px auto;
}

div.top p.test_name {
  color: #fff;
  margin: 30px auto 10px;
  font-size: 24px;
  font-weight: bold;
}

div.top p.test_timer {
  margin-top: 30px;
  margin-bottom: 10px;
  font-weight: bold;
}

div.top p.start {
  width: 240px;
  height: 60px;
  text-align: center;
  background: #fff;
  border-radius: 4px;
  margin: 30px auto;
  font-size: 20px;
  line-height: 60px;
}

div.top p.start a {
  color: #44b035;
  width: 100%;
  height: 100%;
  display: block;
}

div.top p.start:hover {
  background: #1ac875;
}

div.top p.start a:hover {
  color: #fff;
}

.modal_wrap input {
  display: none;
}

.modal_overlay {
  z-index: 9999;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: #000000b3;
  justify-content: center;
  transition: opacity .5s, transform 0s .5s;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  transform: scale(0);
}

.modal_trigger {
  width: 100%;
  height: 100%;
  position: absolute;
}

.modal_content {
  background: #fff;
  align-self: center;
  line-height: 1.4em;
  transition: all .5s;
  position: relative;
  transform: scale(.3);
}

.close_button {
  cursor: pointer;
  text-indent: 100%;
  white-space: nowrap;
  background: url("close_icon.d9a5da31.svg") center / 30px no-repeat;
  position: absolute;
  top: 20px;
  right: 16px;
  overflow: hidden;
}

.close_button:hover {
  background-image: url("close_icon_d.e9bd9539.svg");
}

.modal_content {
  height: 90vh;
  width: 80vw;
  max-width: 1280px;
  display: block;
}

.modal_wrap input:checked ~ .modal_overlay {
  opacity: 1;
  transition: opacity .5s;
  transform: scale(1);
}

.modal_wrap input:checked ~ .modal_overlay .modal_content {
  border-radius: 10px;
  transform: scale(1);
}

.modal_content h2 {
  font-size: 28px;
  font-weight: bold;
  line-height: 66px;
  text-align: center !important;
  border-bottom: 1px solid #ccc !important;
}

.open_button {
  width: 200px;
  height: 30px;
  color: #444;
  cursor: pointer;
  background: #fff;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 8px 16px;
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  display: flex;
  position: fixed;
  inset: 0;
  box-shadow: 0 1px 3px #0009;
}

.open_button_toterm {
  text-align: center;
  cursor: pointer;
  margin: 50px auto 10px;
  display: block;
}

.open_button_toinstraction {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: block;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  box-shadow: inset 0 0 6px #0000001a;
}

::-webkit-scrollbar-thumb {
  background-color: #32323280;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #ffffff4d;
}

div.operationWrapper {
  width: 1080px;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  padding: 20px;
  display: flex;
}

ul.operation {
  width: 720px;
  box-sizing: border-box;
  border: 3px solid #33b035;
  border-radius: 4px;
  justify-content: space-between;
  margin: 20px auto;
  padding: 20px;
  display: flex;
}

ul.operation2 {
  width: 300px;
  box-sizing: border-box;
  justify-content: flex-end;
  margin: 20px auto;
  display: flex;
}

ul.operation li, ul.operation2 li {
  width: 150px;
  height: 35px;
  text-align: center;
  border-radius: 4px;
  line-height: 35px;
}

ul.operation2 li {
  width: 150px;
  height: 35px;
  text-align: center;
  border-radius: 4px;
  margin-left: 20px;
  line-height: 35px;
}

ul.operation li a, ul.operation2 li a {
  width: 100%;
  height: 100%;
  color: #fff;
  display: block;
}

ul.operation li.save, ul.operation2 li.save {
  background: #44b035;
}

ul.operation li.discard, ul.operation2 li.discard {
  background: #ccc;
}

ul.operation li.save:hover, ul.operation2 li.save:hover {
  background: #ff432f;
}

ul.operation li.discard:hover, ul.operation2 li.discard:hover, ul.operation li.preview, ul.operation2 li.preview {
  background: #44b035;
}

ul.operation li.preview:hover, ul.operation2 li.preview:hover {
  background: #ff432f;
}

div.contentsInfoWrapper {
  width: 1080px;
  justify-content: space-between;
  margin: auto;
  display: flex;
}

div.topImage {
  width: calc(40% - 5px);
  text-align: center;
}

div.topImage h2 {
  text-align: center;
  margin-bottom: 10px;
}

div.title h2:after, div.topImage h2:after {
  content: "※";
  color: red;
  padding-left: 3px;
}

dl.contentsInfo {
  width: calc(60% - 5px);
  border-bottom: 1px solid #ccc;
  margin: 20px auto;
  padding-left: 3px;
  overflow: hidden;
}

.qr dl.contentsInfo {
  width: 80%;
  border-bottom: 1px solid #ccc;
  margin: 20px auto;
  padding-left: 3px;
  overflow: hidden;
}

dl.contentsInfo dt {
  width: 120px;
  float: left;
  clear: both;
  border-top: 1px solid #ccc;
  margin-bottom: 30px;
  padding-top: 10px;
}

.qr dl.contentsInfo dt {
  width: 160px;
}

.must:after {
  content: "※";
  color: red;
}

dl.contentsInfo dd {
  width: calc(100% - 120px);
  float: right;
  border-top: 1px solid #ccc;
  padding-top: 10px;
}

.qr dl.contentsInfo dd {
  width: calc(100% - 160px);
}

input[type="text"], input[type="password"] {
  background: #efefef;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0 5px;
}

input[type="text"].full {
  width: calc(100% - 12px);
}

input[type="text"].half, input[type="password"].half {
  width: calc(50% - 12px);
  margin-bottom: 10px;
}

input[type="text"].quarter, input[type="password"].quarter {
  width: calc(35% - 12px);
  margin-bottom: 10px;
}

input[type="text"].w40 {
  width: calc(40% - 12px);
}

input[type="text"].w45 {
  width: calc(45% - 12px);
}

input[type="text"].w100px {
  width: 100px;
}

input[type="text"].calendar {
  cursor: pointer;
}

input[type="button"] {
  text-align: center;
  color: #fff;
  cursor: pointer;
  background: #44b035;
  border-radius: 4px;
  padding: 5px 20px;
}

input[type="button"]:hover {
  background: #ff432f;
}

input[type="checkbox"] {
  width: 20px;
  height: 20px;
  vertical-align: top;
  border: 3px solid #666;
  margin-right: 5px;
}

input[type="radio"] {
  width: 20px;
  height: 20px;
  vertical-align: top;
  border: 3px solid #666;
  border-radius: 10px;
  margin-right: 5px;
}

input[type="checkbox"]:checked, input[type="radio"]:checked {
  background: #44b035;
  border-color: #44b035;
}

input[type="checkbox"] + label {
  margin-right: 20px;
}

label {
  margin-right: 10px;
}

textarea {
  height: 150px;
  width: calc(100% - 22px);
  background: #efefef;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 10px;
  display: block;
}

ul.lang {
  margin-top: 10px;
  margin-bottom: 10px;
}

ul.lang li {
  width: calc(100% - 12px);
  height: 25px;
  text-indent: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 5px;
  padding: 5px;
  line-height: 25px;
  position: relative;
}

ul.lang span {
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 24px;
  display: block;
  position: absolute;
  top: 5px;
}

ul.lang span:hover {
  background-position: top;
}

ul.lang span.delete, div.collectionList span.delete {
  cursor: pointer;
  background-image: url("lang_delete.a703b3b7.svg");
  right: 45px;
}

ul.lang span.move, div.collectionList span.move {
  cursor: move;
  background-image: url("lang_move.2d064d1b.svg");
  right: 10px;
}

div.collectionList span {
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 24px;
  display: block;
  position: absolute;
  top: 10px;
}

div.collectionList span.delete {
  right: 50px;
}

div.collectionList span.move {
  right: 20px;
}

div.contentsBox {
  width: 920px;
  border: 2px solid #999;
  border-radius: 4px;
  margin: 20px auto;
  padding: 20px;
  position: relative;
}

div.contentsHeader {
  justify-content: flex-start;
  display: flex;
}

div.contentsBox h2 {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
}

div.number {
  width: 130px;
  text-align: center;
  pointer-events: stroke;
  margin-right: 40px;
  font-size: 18px;
  font-weight: bold;
}

.must:after {
  content: "※";
  color: red;
  padding-left: 3px;
}

input[type="text"].number {
  height: 72px;
  text-align: center;
  font-size: 48px;
}

div.contentsBox p.close, div.collectionList p.close {
  width: 80px;
  text-align: center;
  cursor: pointer;
  border: 2px solid #44b035;
  border-radius: 4px;
  line-height: 24px;
  position: absolute;
  top: 10px;
  right: 20px;
}

div.contentsBox p.delete, div.collectionList p.delete {
  width: 80px;
  text-align: center;
  cursor: pointer;
  border: 2px solid #44b035;
  border-radius: 4px;
  line-height: 24px;
  position: absolute;
  top: 10px;
  right: 110px;
}

div.qr p.delete {
  right: 20px;
}

div.contentsBox p.close:hover, div.contentsBox p.delete:hover, div.collectionList p.close:hover, div.collectionList p.delete:hover {
  color: #fff;
  background: #44b035;
}

div.contentsBox div.title {
  width: calc(100% - 150px);
}

dl.title {
  overflow: hidden;
}

dl.title dt {
  width: 100px;
  float: left;
  clear: both;
  margin-bottom: 20px;
}

dl.title dd {
  width: calc(100% - 110px);
  float: right;
}

div.indent, dl.indent {
  margin-bottom: 10px;
  margin-left: 100px;
}

dl.indent dt {
  width: 80px;
  float: left;
  clear: both;
}

dl.indent dd {
  width: calc(100% - 100px);
  float: right;
}

div.indent h3 {
  margin: 5px 0;
}

img#preview, img#preview1, img#preview2 {
  width: 240px;
  height: 160px;
  object-fit: contain;
  outline: 4px dashed #ccc;
  margin: 10px 0;
  display: block;
}

img#preview1 {
  height: 80px;
}

img#topImage {
  width: 240px;
  height: 480px;
  object-fit: contain;
  outline: 4px dashed #ccc;
  margin: 10px auto;
  display: block;
}

p.addContents {
  width: 200px;
  height: 40px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  background: #44b035;
  border-radius: 4px;
  margin: 20px auto;
  line-height: 40px;
}

p.addContents a {
  color: #fff;
}

p.addContents:hover, p.addContents:hover a {
  color: #fff;
  background: #ff432f;
}

table.contentsList {
  width: 100%;
  margin: 20px auto;
}

table.contentsList thead {
  border-bottom: 1px solid #ccc;
}

table.contentsList th {
  text-align: center;
  padding: 3px 0;
}

table.contentsList td {
  border-bottom: 1px solid #ccc;
  padding: 10px;
}

table.contentsList tr td:nth-child(7) {
  text-align: center;
}

table.contentsList span.publish {
  color: #44b035;
  font-weight: bold;
}

table.contentsList a {
  color: #44b035;
  border: 2px solid #44b035;
  border-radius: 4px;
  padding: 2px 5px;
  font-size: 14px;
}

table.contentsList a:hover {
  color: #fff;
  background: #44b035;
}

div.listPaginate {
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  display: flex;
}

div.listPaginate a {
  width: 24px;
  height: 24px;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 24px;
  margin: 0 10px;
  display: block;
}

div.listPaginate a:hover {
  background-position: top;
}

div.listPaginate a.prev {
  background-image: url("page_prev.2b0829a8.svg");
}

div.listPaginate a.next {
  background-image: url("page_next.b704f5d0.svg");
}

div.constraints_passwd, div.constraints_gps, div.constraints_gps2 {
  margin: 5px 0 5px 30px;
}

div.constraints_gps input, div.constraints_gps2 input {
  margin-bottom: 5px;
}

div.constraints_passwd {
  margin-left: calc(37px + 2em);
}

div.collection {
  width: 618px;
  border: 1px solid #333;
  border-radius: 4px;
  margin: 20px auto 40px;
  padding: 20px;
  position: relative;
}

div.collection h3 {
  position: absolute;
  top: -.75em;
  left: 30px;
}

div.collection h3 span {
  background: #fff;
  padding: 0 10px;
}

table.collection_item {
  width: 100%;
}

table.collection_item thead {
  text-align: center;
  border-bottom: 2px solid #ccc;
}

table.collection_item td {
  padding: 10px;
}

table.collection_item tr td:first-child, table.collection_item tr td:nth-child(2) {
  width: 40%;
}

table.collection_item select, div.collectionList select, div.qr select {
  height: 36px;
  background: url("arrow_down.ab1262c2.svg") right 5px center / 10px no-repeat;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0 20px 0 10px;
  line-height: 36px;
}

p.addCollection {
  width: 80px;
  height: 30px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  background: #44b035;
  border-radius: 4px;
  line-height: 30px;
}

div.collectionList {
  width: 918px;
  border: 1px solid #333;
  border-radius: 4px;
  margin: 20px auto;
  padding: 50px 20px 20px;
  position: relative;
  overflow: hidden;
}

dl.collectionList {
  width: 100%;
}

dl.collectionList dt {
  width: 20%;
  float: left;
  clear: both;
}

dl.collectionList dd {
  width: 80%;
  float: right;
  margin-bottom: 10px;
}

textarea.addItem {
  width: calc(100% - 12px);
  height: 120px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 10px;
  padding: 10px;
}

div.error {
  max-width: 200px;
  max-height: 120px;
  color: #fff;
  text-align: justify;
  background: #f00c;
  margin: 1.5em 0;
  padding: 7px 10px;
  position: absolute;
  top: -85px;
  left: 20px;
}

div.error:before {
  content: "";
  border: 15px solid #0000;
  border-top-color: #f00c;
  margin-left: -15px;
  position: absolute;
  top: 100%;
  left: 20%;
}

dl.voiceguideTitle {
  width: 956px;
  border: 2px solid #44b035;
  border-radius: 4px;
  margin: 20px auto;
  overflow: hidden;
}

dl.voiceguideTitle dt {
  width: 20%;
  float: left;
  text-align: center;
  padding: 10px 0;
}

dl.voiceguideTitle dd.title {
  width: 65%;
  float: left;
  text-indent: 20px;
  padding: 10px 0;
  font-size: 18px;
  font-weight: bold;
}

dl.voiceguideTitle dd.status {
  width: 15%;
  float: left;
  text-indent: 20px;
  text-align: center;
  padding: 10px 0;
}

dd.status span {
  color: #fff;
  border-radius: 4px;
  padding: 3px 6px;
}

dd.status span.nopublic {
  background-color: #ccc;
}

dd.status span.public {
  background-color: #44b035;
}

div.rental {
  background: #ccc;
  margin: 30px 10px 10px;
  padding: 20px 40px;
  display: inline-block;
}

div.rental h3 {
  text-align: left;
  border-bottom: 1px solid #333;
  margin-bottom: 10px;
}

div.rental p.attention {
  color: red;
  margin-bottom: 10px;
}

span#sea {
  cursor: pointer;
  padding-left: 10px;
}

div.previewWrapper {
  width: 1080px;
  margin: 50px auto;
  overflow: hidden;
}

div.preview {
  width: 292px;
  height: 565px;
  height: 600px;
  float: left;
  background: url("smartphoneBg.1b8a45a9.png") 0 0 / 100% no-repeat;
  padding: 48px 17px;
  position: relative;
}

div.preview img.top {
  width: 292px;
  height: 565px;
  object-fit: cover;
  z-index: 0;
  position: absolute;
  top: 48px;
  left: 17px;
}

ul.language {
  z-index: 2;
  margin: auto;
  position: absolute;
  top: 330.5px;
  left: 0;
  right: 0;
}

ul.language li {
  width: calc(100% - 60px);
  height: 45px;
  text-align: center;
  background: #fff;
  margin: 10px 30px;
  line-height: 45px;
}

div.preContents {
  padding: 20px;
}

div.preImg {
  width: 700px;
  float: right;
  position: relative;
}

p.preNum {
  width: 128px;
  height: 64px;
  float: left;
  text-align: center;
  color: #44b035;
  align-items: center;
  font-size: 64px;
  font-weight: bold;
  line-height: 1em;
}

p.preTitle {
  width: 572px;
  color: #44b035;
  text-align: left;
  float: right;
  margin-top: 5px;
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 2em;
}

div.preImageWrapper {
  width: 350px;
  height: 250px;
  marin: 20px;
  float: left;
  clear: both;
  text-align: center;
  background: url("https://dummyimage.com/350x250/333333/ffffff&text=画像無し") no-repeat;
  margin-bottom: 50px;
}

div.preImageWrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: 50% 50%;
  margin: auto;
}

div.preContents audio {
  width: 300px;
  float: right;
  margin: 20px auto;
}

dl.preview {
  width: 100%;
  border-bottom: 2px solid #44b035;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
  padding: 0 40px;
  display: flex;
}

dl.preview dt {
  width: 120px;
  text-align: right;
  margin-right: 10px;
  font-size: 60px;
}

dl.preview dd {
  width: calc(100% - 130px);
  font-size: 24px;
}

div.preTextWrapper {
  height: 400px;
  clear: both;
  box-sizing: border-box;
  width: 100%;
  background: #efefef;
  padding: 20px 30px 20px 20px;
  overflow-y: auto;
}

button.slick-prev {
  background: url("arrow_prev.73e64af9.svg") 0 0 / 30px no-repeat;
  right: 700px;
}

button.slick-prev:hover {
  background-image: url("arrow_prev_on.86495f53.svg") !important;
}

button.slick-next {
  background: url("arrow_next.6bce1284.svg") 0 0 / 30px no-repeat;
  right: 0;
}

button.slick-next:hover {
  background-image: url("arrow_next_on.2c88a836.svg") !important;
}

button.slick-arrow {
  text-indent: -9999px;
  width: 30px;
  height: 50px;
  z-index: 1;
  cursor: pointer;
  border: none;
  position: absolute;
  top: 50px;
}

li.slick-active {
  background-color: #b2f0f4 !important;
}

table.report {
  width: calc(100% - 100px);
  margin: 20px auto;
}

table.report tr td {
  padding: 5px;
}

table.report tr:nth-child(2n) td {
  background: #efefef;
  text-align: center !important;
}

table.report tr:nth-child(odd) td {
  text-align: center !important;
}

table.report thead {
  background: #ccc;
}

table.report thead th {
  text-align: center;
  padding: 5px;
}

table.report tfoot th {
  color: #fff;
  text-align: center;
  background: #333;
  padding: 5px;
}

div.reportDuration {
  margin: 20px 0;
}

div.page {
  width: calc(100% - 10px);
  text-align: center;
  justify-content: center;
  margin: 10px auto;
  padding: 5px;
  font-family: proxima-nova, proxima nova, open sans, Arial, Helvetica, sans-serif;
  display: flex;
  overflow: hidden;
}

div.page span.current_page {
  min-width: 30px;
  height: 30px;
  text-align: center;
  color: #fff;
  background: #44b035;
  padding: 1px;
  line-height: 30px;
}

div.page a, a.link_next, a.link_before {
  min-width: 30px;
  height: 30px;
  text-align: center;
  background: #ccc;
  padding: 1px;
  line-height: 30px;
  display: block;
}

a.link_next {
  margin-left: 4px;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

a.link_before {
  margin-right: 4px;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

div.page a:hover {
  color: #fff;
  background: #44b035;
}

/*# sourceMappingURL=index.afa82ea2.css.map */
